import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Colors, Title, FlexBox } from '../Helpers/Tools'

const Container = styled(FlexBox)`
  background-color: ${Colors.callout};
  height: auto;
  width: 75%;
  padding: 12px 24px;
  border-radius: 10px;
  box-shadow: 0px 6px 20px hsla(0, 0%, 40%, 0.3),
    0px 5px 15px hsla(0, 0%, 50%, 0.1);
`
const formatDate = date => {
  let hours = date.getHours() % 12
  let minutes = date.getMinutes()

  const ampm = date.getHours() >= 12 ? 'pm' : 'am'
  // this guards against a 00:__ when it's redeemed at 12pm or 12am
  hours = hours === 0 ? '12' : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  // date.getMonth() returns 0 ~ 11
  return `${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}${ampm}`
}

const formatCountDown = seconds => {
  const date = new Date(seconds * 1000)
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const sec =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return `${minutes}m ${sec}s`
}
const Countdown = ({ redeemedAt, onTimerEnd, duration = 30 * 60 }) => {
  const [timeLeft, setTimeLeft] = useState(
    duration - Math.abs((redeemedAt - new Date()) / 1000)
  )
  const redeemedOnText = 'Redeemed: ' + formatDate(redeemedAt)

  useEffect(() => {
    const id = setInterval(() => {
      setTimeLeft(prev => prev - 1)
    }, 1000)

    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    timeLeft < 1 && onTimerEnd(redeemedOnText)
  }, [timeLeft])

  return (
    <Container d='column' j='space-around' a='center'>
      <Title c={Colors.countdownDetailText} ft='24px'>
        show staff within:
      </Title>
      <Title c={Colors.countdownText} ft='50px'>
        {formatCountDown(timeLeft)}
      </Title>
      <Title c={Colors.countdownDetailText} ft='22px'>
        {redeemedOnText}
      </Title>
    </Container>
  )
}

export default Countdown
