import React, { useState, useEffect, useRef } from 'react'

import queryString from 'query-string'
import api from '../api'
//Components
import { FlexBox, Title, Dimensions } from '../Helpers/Tools'
import Deal from '../components/Deal'
import MainButton from '../components/MainButton'
import UsedButton from '../components/UsedButton'
import Callout from '../components/Callout'
import ConfettiAnimation from '../components/ConfettiAnimation'
import FadeWithDelay from '../components/FadeWithDelay'
import FadeAndHover from '../components/FadeAndHover'
import Countdown from '../components/Countdown'
import Layout from '../components/layout'

const couponState = {
  LOADING: 'loading',
  EXPIRED: 'expired',
  LOADED: 'loaded',
  REDEEMED: 'redeemed',
  OUTOFTIME: 'outOfTime'
}
const parseQueryParams = window => queryString.parse(window.location.search)
const delayStart = 100
const timerDuration = 10 * 60
// Time checking logic is in the countdown, but if the coupon is already expired, we never evaluate it.
const isOutOfTime = date => {
  const now = new Date()
  return Math.abs((now.getTime() - date.getTime()) / 1000 / 60) > 30
}

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Redeem = () => {
  const [state, setState] = useState(couponState.LOADING)
  const prevState = usePrevious(state)
  const [showConfetti, setConfetti] = useState(false)
  const [couponInfo, setCouponInfo] = useState(null)

  const loadedCouponHandler = res => {
    const { coupon } = res.data
    setCouponInfo(coupon)
    if (coupon.isExpired) {
      setState(couponState.EXPIRED)
    } else if (coupon.isRedeemed) {
      setState(
        isOutOfTime(new Date(coupon.redeemedAt))
          ? couponState.OUTOFTIME
          : couponState.REDEEMED
      )
    } else {
      setState(couponState.LOADED)
    }
  }

  useEffect(() => {
    const source = api.getCancelToken()
    const loadCoupon = () => {
      const params = parseQueryParams(window)
      api
        .issueCoupon({
          userId: params.userId,
          subId: params.subId,
          ...params
        })
        .then(res => {
          loadedCouponHandler(res)
        })
        .catch(error => {
          console.log(error)
        })
    }

    loadCoupon()

    return () => {
      source.cancel()
    }
  }, [])
  
  useEffect(() => {
    if (prevState === couponState.LOADED) {
      setConfetti(true)
    }
  }, [state])

  const handleRedeemRequest = () => {
    const params = parseQueryParams(window)
    api
      .redeemCoupon({
        couponId: couponInfo && couponInfo.id,
        ...params
      })
      .then(res => {
        loadedCouponHandler(res)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const onTimerEnd = () => {
    setState(couponState.OUTOFTIME)
  }
  const createViewFromState = () => {
    switch (state) {
      case couponState.LOADING:
        return null
      case couponState.EXPIRED:
        return (
          <Title ft='28px' mt='24px' c='rebeccapurple'>
            This coupon has expired!
          </Title>
        )
      case couponState.OUTOFTIME:
        return (
          <UsedButton mt='8px' w='65%' h='50px' min-h>
            Redeemed
          </UsedButton>
        )
      case couponState.REDEEMED:
        return (
          <Countdown
            redeemedAt={
              couponInfo.redeemedAt && new Date(couponInfo.redeemedAt)
            }
            onTimerEnd={onTimerEnd}
            duration={timerDuration}
          />
        )
      case couponState.LOADED:
        return (
          <>
            <FadeAndHover
              delay={delayStart + 2000}
              animate={state !== couponState.REDEEMED}
            >
              <Callout w='65%'>
                Tap here to use your deal! You will have 30 minutes to show the
                restaurant
              </Callout>
            </FadeAndHover>
            <FadeWithDelay
              delay={delayStart + 600}
              animate={state !== couponState.REDEEMED}
            >
              <MainButton mt='8px' w='70%' onClick={handleRedeemRequest}>
                Redeem
              </MainButton>
            </FadeWithDelay>
          </>
        )
      default:
        break
    }
  }
  return (
    <Layout bgShape={require('../images/Mural.svg')}>
      <ConfettiAnimation active={showConfetti} />
      <FlexBox
        d='column'
        j='space-between'
        a='center'
        maxH={Dimensions.maxHeight}
      >
        <Deal mt='20px'>{couponInfo ? couponInfo.name : 'Loading'}</Deal>
        <FlexBox d='column' j='start' a='center' mt='20px'>
          {createViewFromState()}
          {state !== couponState.EXPIRED && (
            <FadeWithDelay
              delay={delayStart + 900}
              animate={state !== couponState.REDEEMED}
            >
              <Title mt='20px' w='60%' ta='center' ftSize='15px'>
                {couponInfo && couponInfo.description}
              </Title>
            </FadeWithDelay>
          )}
        </FlexBox>
      </FlexBox>
    </Layout>
  )
}

export default Redeem
