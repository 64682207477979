import axios from 'axios'

const apiRoot = process.env.API_ROOT || 'http://localhost:9000'
const baseEndpoint = `${apiRoot}/api`
const couponsEndpoint = `${baseEndpoint}/coupons`

export const issueCoupon = ({ subId, promoId }) =>
  axios.post(`${couponsEndpoint}/issue`, {
    subId,
    promoId
  })

export const redeemCoupon = ({ userId, promoId, ...rest }) =>
  axios.post(`${couponsEndpoint}/redeem`, {
    userId,
    promoId,
    ...rest
  })

export default {
  issueCoupon,
  redeemCoupon,
  getCancelToken: () => axios.CancelToken.source()
}
