import React from 'react'

export const ShareLogo = () => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Review-3" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Redemption-Main" transform="translate(-267.000000, -674.000000)" fill="#000000" fillRule="nonzero">
          <g id="Share-Icon" transform="translate(254.000000, 662.000000)">
            <g id={25419} transform="translate(13.000000, 12.000000)">
              <path d="M24.7351098,8.35907408 L17.5921619,0.4500481 C17.4154218,0.254348699 17.2062362,0.156471946 16.9644096,0.156471946 C16.7225831,0.156471946 16.5132509,0.254348699 16.3366574,0.4500481 C16.1599173,0.645855711 16.0714251,0.877589339 16.0714251,1.14530309 L16.0714251,5.09981608 L12.9464434,5.09981608 C6.31485438,5.09981608 2.24606989,7.17497638 0.739161538,11.3251888 C0.246370891,12.7049859 0,14.419858 0,16.46921 C0,18.1786175 0.59061582,20.5008233 1.77174973,23.4359355 C1.79960205,23.5081663 1.84827032,23.6314726 1.91814545,23.8065037 C1.98797171,23.9814266 2.05066386,24.1358977 2.10646623,24.2699169 C2.16246406,24.4036114 2.22295735,24.5169083 2.28799497,24.6094827 C2.39950198,24.784622 2.52977267,24.872381 2.67861157,24.872381 C2.8181175,24.872381 2.92742564,24.8209268 3.00653601,24.7179641 C3.08545092,24.6150555 3.12498168,24.4861764 3.12498168,24.332084 C3.12498168,24.2391309 3.11335211,24.1028934 3.09004411,23.9225599 C3.06678498,23.7422265 3.05510655,23.6215172 3.05510655,23.5596206 C3.00853942,22.8596043 2.98528029,22.225759 2.98528029,21.6597619 C2.98528029,20.6196929 3.06678498,19.6876725 3.22940345,18.863863 C3.39226624,18.0398911 3.61777232,17.3268896 3.90616602,16.7243713 C4.19451084,16.1216366 4.56641264,15.6019538 5.02226231,15.1642407 C5.47791653,14.7265277 5.96845944,14.3687825 6.49393992,14.0906805 C7.01951813,13.8125244 7.63798627,13.5936678 8.34949094,13.4341109 C9.06094674,13.2744998 9.7770446,13.163746 10.4979311,13.1019034 C11.2188176,13.0400609 12.0349395,13.009329 12.9464434,13.009329 L16.0714251,13.009329 L16.0714251,16.9639502 C16.0714251,17.231664 16.1597218,17.4634517 16.3363153,17.6590429 C16.513202,17.8545259 16.7223876,17.9525108 16.9640676,17.9525108 C17.2058453,17.9525108 17.4151775,17.8545259 17.5921619,17.6590429 L24.7349632,9.74974638 C24.9117033,9.55404698 25,9.32242156 25,9.0546537 C25,8.78693995 24.9117033,8.55515222 24.7351098,8.35907408 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const CallLogo = () => {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Review-3" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Redemption-Main" transform="translate(-81.000000, -672.000000)" fill="#000000" fillRule="nonzero">
          <g id={126341} transform="translate(81.000000, 672.000000)">
            <path d="M22.7871518,19.8274428 C21.8079418,18.8607069 20.5854886,18.8607069 19.6125156,19.8274428 C18.8703119,20.5634096 18.1281081,21.2993763 17.3983784,22.047817 C17.1987942,22.2536383 17.030395,22.2972973 16.7871518,22.1600832 C16.3069023,21.8981289 15.7954678,21.6860707 15.3339293,21.3991684 C13.1821622,20.045738 11.3796674,18.3056133 9.78299376,16.3471933 C8.99089397,15.3742204 8.28611227,14.3326403 7.79338877,13.1600832 C7.69359667,12.9230769 7.71230769,12.7671518 7.90565489,12.5738046 C8.64785863,11.8565489 9.37135135,11.1205821 10.1010811,10.3846154 C11.1177131,9.36174636 11.1177131,8.16424116 10.0948441,7.13513514 C9.51480249,6.54885655 8.93476091,5.97505198 8.35471933,5.38877339 C7.75596674,4.79002079 7.16345114,4.18503119 6.55846154,3.59251559 C5.57925156,2.63825364 4.35679834,2.63825364 3.38382536,3.5987526 C2.63538462,4.33471933 1.9181289,5.08939709 1.15721414,5.81288981 C0.452432432,6.48024948 0.0969230769,7.2972973 0.0220790021,8.25155925 C-0.0964241164,9.8045738 0.284033264,11.2702703 0.8204158,12.6985447 C1.9181289,15.6548857 3.58964657,18.2806653 5.6166736,20.6881497 C8.35471933,23.9438669 11.6229106,26.5197505 15.4461954,28.3783784 C17.1676091,29.2141372 18.9513929,29.8565489 20.8911019,29.962578 C22.2258212,30.037422 23.3859044,29.7006237 24.3152183,28.6590437 C24.9513929,27.9480249 25.6686486,27.2993763 26.3422453,26.6195426 C27.3401663,25.6091476 27.3464033,24.3866944 26.3547193,23.3887734 C25.1696881,22.1975052 23.97842,21.012474 22.7871518,19.8274428 Z" id="Path" />
            <path d="M21.5958836,14.8565489 L23.8973389,14.4636175 C23.5355925,12.3492723 22.5376715,10.4345114 21.022079,8.91268191 C19.4191684,7.30977131 17.3921414,6.2993763 15.1592931,5.98752599 L14.8349688,8.3014553 C16.5626195,8.54469854 18.1343451,9.32432432 19.3755094,10.5654886 C20.5480665,11.7380457 21.3152183,13.2224532 21.5958836,14.8565489 Z" id="Path" />
            <path d="M25.1946362,4.85239085 C22.5376715,2.1954262 19.1759252,0.517671518 15.4649064,0 L15.1405821,2.31392931 C18.3464033,2.76299376 21.2528482,4.21621622 23.5480665,6.50519751 C25.7247817,8.68191268 27.1530561,11.4324324 27.6707277,14.4573805 L29.972183,14.0644491 C29.3671933,10.5592516 27.7143867,7.37837838 25.1946362,4.85239085 Z" id="Path" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Triangle = ({color}) => {
  return (
    <svg width="31px" height="10px" viewBox="0 0 31 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Review-3" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Redemption-Main" transform="translate(-173.000000, -502.000000)" fill={color}>
          <g id="Stacked-Group" transform="translate(45.000000, 405.000000)">
            <polygon id="Triangle" transform="translate(143.500000, 102.000000) rotate(-180.000000) translate(-143.500000, -102.000000) " points="143.5 97 159 107 128 107" />
          </g>
        </g>
      </g>
    </svg>
  )
}