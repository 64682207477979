/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Box, Col, LoadFont } from 'boostly-ui2'

const Layout = ({ bgShape, children }) => (
  <Col
    minHeight={`100vh`}
    p={3}
    css={css`
      background-image: url("${bgShape}");
      background-repeat: no-repeat;
      background-position: center top;
    `}
  >
    <LoadFont />
    <Box
      css={css`
        font-family: righteous;
      `}
      maxWidth={`450px`}
      m={`0 auto`}
    >
      {children}
    </Box>
  </Col>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
